<template>
    <div>
      <div class="d-flex justify-content-between">
        <h1>{{moduleNamePlural}}</h1>
        <button class="btn btn-unidark" @click="addItem()" id='btnNew' v-if="newButtonName"><i class="fal fa-plus"></i>&ensp;{{newButtonName}}</button>
      </div>

        <DataTable ref="dt" 
            :value="items.data" dataKey="id" 
            :totalRecords="items.total"
            :sortField="sort" :sortOrder="sortOrder"
            :paginator="true" :rows="15" :alwaysShowPaginator="false" :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" responsiveLayout="scroll"
            :lazy="true" :loading="loading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" 
        >
            <template #header>
                <div class='row'>
                    <div :class="'form-group search ' + (filterOptions ? 'col-10' : 'w-100')">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        <input type="text" class="form-control" id="search" :placeholder="$t('overview.search')"  @change="onFilter()" @input="onInput()" v-model="filters['global'].value" autocomplete="off">
                    </div>
                    <div class="col-2 ps-4" v-show="filterOptions">
                        <button v-if="filterOptions" type="button" class="btn btn-unilight w-100 h-100" data-bs-toggle="modal" data-bs-target="#filterModal" @click="$refs.overviewfilters.onOpen" :disabled="loading">
                            <i class="fa" :class="{'fa-bars-filter': (!loading || !filtersActive), 'fa-spinner fa-spin': loading && filtersActive}"></i>&emsp;{{'Filter (' + filtersActive + ')'}}
                        </button>
                    </div>
                </div>
            </template>

            <slot name="columns"></slot>
            
            <Column :exportable="false" style="width: 3rem">
                <template #body="slotProps">
                    <button type="button" id="dropdownMenuButton1" class="btn btn-secondary btn-sec-overview btn-sm" data-bs-toggle="dropdown" aria-expanded="false" @click="ignoreLink">
                      <i class="fa-light fa-ellipsis"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                        <!--<li>
                            <a class="dropdown-item" v-if="downloadFileType && (!slotProps.data.status || slotProps.data.status != 'draft')" @click="downloadPDF(slotProps.data)">
                                <i class="fal fa-download"></i> Download {{fileTypes[downloadFileType]}}
                            </a>
                        </li>-->
                        <li>
                            <a class="dropdown-item edit-button" @click="editItem(slotProps.data)">
                                <i class="fal fa-pencil-alt"></i> {{$t('overview.edit')}}
                            </a>
                        </li>
                        <li v-if="showDelete">
                            <span class="dropdown-item dp__pointer" data-bs-toggle="modal" data-bs-target="#deleteModal" @click="delItem(slotProps.data); ignoreLink($event)">
                                <i class="fal fa-trash-alt"></i> {{$t('overview.delete')}}
                            </span>
                        </li>
                    </ul>
                </template>
            </Column>
            
        </DataTable>

        <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{'Verwijder ' + moduleName}}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        Weet u zeker dat u <b>{{item? fullLocationName(item) : 'dit'}}</b> wilt verwijderen?
                    </div>
                    <div class="modal-footer d-flex">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Nee</button>
                        <button type="button" class="btn btn-danger" @click.prevent="del">Ja</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- For filtering -->
        <Filters :filterOptions="filterOptions" ref="overviewfilters" />
    </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Filters from '@/components/OverviewFilter.vue';

export default {
    name: 'Overview',
    props: {
        'moduleNamePlural': {},
        'moduleName': {},
        'newButtonName': {},
        'sort': {},
        'sortOrder': { type: Number, default: 1},
        'itemService': {},
        'itemFormRef': {},
        'modalSize': {},
        'modalHeight': {},
        'modalWidth': {},
        'selectFields': { type: Array },
        'showDelete': { type: Number, default: 1},
        'filterOptions': {}
    },
    components: {
        DataTable,
        Column,
        Filters
    },
    emits: 'refreshList',
    data() {
        return {
            items: {
                'data': [],
                'total': 0
            },
            loading: false,
            lazyParams: {},
            page: 1,
            filters: {},
            filtersActive: 0,
            filterData: [],
            
            item: {},
            itemLoading: false
        }
    },
    methods: {
        onPage(event) {
            this.page = event.page + 1
            this.getIndex();
        },
        onSort(event) {
            this.page = 1;
            this.lazyParams = event;
            this.lazyParams.route = this.$route.name
            this.getIndex();
        },
        onFilter() {
            this.lazyParams.filters = this.filters;
            this.getIndex();
        },
        onInput() {
            if(this.filters['global'].value == '') {
                this.onFilter();
            }
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
        getIndex(fromState = null) {
            this.loading = true
            this.item = {}
            var storedEmployeeOverviewData = this.$parent.$parent.$parent.storedEmployeeOverviewData[this.$route.name];
            if(storedEmployeeOverviewData && fromState && fromState.params && fromState.params.id){
                this.page = storedEmployeeOverviewData.page;
                this.lazyParams.filters.global.value = storedEmployeeOverviewData.filter;
                this.lazyParams.sortField = storedEmployeeOverviewData.sortField;
                this.lazyParams.sortOrder = storedEmployeeOverviewData.sortOrder;
                this.$refs.dt.d_first = (storedEmployeeOverviewData.page - 1) * 20;
                this.$refs.dt.$forceUpdate();
            }
            const pageNumber = this.page;
            const storedFilters = sessionStorage.getItem("filter_" + this.$route.name);
            if(storedFilters){
                this.$refs.overviewfilters.filterData = JSON.parse(storedFilters);
                this.$refs.overviewfilters.save(true);
                return;
            }
            this.itemService.index(this.page, {
                lazyEvent: JSON.stringify(this.lazyParams)
            }).then(response => {
                this.items = response.data;
                this.loading = false
                this.$parent.$parent.$parent.storedEmployeeOverviewData[this.$route.name] = {
                    page: pageNumber,
                    filter: this.lazyParams.filters.global.value,
                    sortField: this.lazyParams.sortField,
                    sortOrder: this.lazyParams.sortOrder
                };
            }).catch(error => {
                console.log('error', error)
            })
        },
        getIndexFiltered(filterData) {
          this.loading = true;
          this.item = {};
          let extraParameters = null;
          let lazyParams = JSON.parse(JSON.stringify(this.lazyParams));
          lazyParams.filterData = filterData;
          //lazyParams.rows = this.rows;
          this.filterData = filterData;
          this.itemService.index(this.page, { lazyEvent: JSON.stringify(lazyParams)}, extraParameters ).then(response => {
            this.$nextTick(() => this.$emit('refreshList'));
            this.items = response.data;
            this.loading = false;
          }).catch(error => {
            console.log('error', error)
          })
        },
        ignoreLink(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        addItem() {
            //this.itemLoading = false;
            //this.itemFormRef.reset();
            //this.item = this.itemFormRef.getData();
            // console.log("Item is now", this.item);
            this.$router.push(window.location.pathname + '/nieuw');
        },
        editItem(item) {
            /*this.itemLoading = true;
            this.itemFormRef.reset();

            this.itemService.show(item.id).then(response => {
                this.itemLoading = false;
                this.item = response.data
                this.itemFormRef.setData(response.data);
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })*/
            this.$router.push(window.location.pathname + "/" + item.id);
        },
        delItem(item) {
            this.item = {...item};
        },
        downloadPDF(item) {
            this.item = {...item};
            this.itemService.downloadPDF(this.item.id).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", this.item.number + ".pdf");
                document.body.appendChild(link);
                link.click();
            }).catch(error => {
                this.toastError(error)
            })

        },
        save() {
            let validate = this.itemFormRef.validate();
            if(!validate) return false;
            let item = this.itemFormRef.getData();
            
            if(this.item && this.item.id) {
                this.itemService.update(item).then(() => {
                    this.itemFormRef.reset();
                    this.getIndex();
                    document.querySelector("#formModal .btn-close").click();
                }).catch(error => {
                    this.toastError(error)
                    console.log('error', error) // create notification-toaster for user error
                })
            } else {
                this.itemService.store(item).then(() => {
                    this.itemFormRef.reset();
                    this.getIndex();
                    document.querySelector("#formModal .btn-close").click();
                }).catch(error => {
                        this.toastError(error)
                    console.log('error', error) // create notification-toaster for user error
                })
            }
            
        },
        del() {
            this.itemService.delete(this.item.id).then(() => {
                this.getIndex();
                document.querySelector("#deleteModal .btn-close").click();
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })
            
        }
    },
    updated() {
        const els = document.querySelectorAll('tr[data-pc-section="bodyrow"]');
        els.forEach(el => {
            el.addEventListener('click', () => {
                const el2 = document.querySelector('tr[data-p-index="'+el.dataset.pIndex+'"] .edit-button');
                if(el2) el2.click();
            });
        });
    },
    created() {
        // this.productService = new ProductService();
        this.initFilters();
    },
    mounted() {
        this.lazyParams = {
          first: 0,
          rows: this.$refs.dt.rows,
          sortField: this.sort,
          sortOrder: this.sortOrder,
          filters: this.filters,
          route: this.$route.name,
          selectFields: this.selectFields
        }
        window.setTimeout(() => {
            if(!this.loading && !this.items.data.length){
                this.getIndex();
            }
        }, 100);
    }
}
</script>

<style scoped>

    .btn-sec-overview {
        border-radius: 3px;
        background-color: #8a8fa0;
        font-size: 14px;
        height: 30px;
        width: 30px;
    }

    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    a.fa-stack {
        cursor: pointer;
    }

    

    
</style>

<style>
.pi-sort-alt:before {
    content: "";
} 
tr[data-pc-section="bodyrow"]:hover{
    background-color: #f0f0f0;
    cursor: pointer;
}
.p-paginator {
        justify-content: center;
    }
.p-paginator .p-link {
        display: inline;
    }
</style>